/*@import url('https://fonts.googleapis.com/css?family=Anton');*/
/*@import url('https://fonts.google.com/specimen/Dancing+Script');*/
/*@import url('https://fonts.google.com/specimen/Lato');*/

.container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.font-dancing-script {
  font-family: "Dancing Script", cursive;
}

.font-lato {
  font-family: "Lato", sans-serif;
}



.ui.container container.p {
  color: black;
}
